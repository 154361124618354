var be = be || {};

be.progressBar = new function () {

    function toArray(nodeList) {
        return Array.prototype.slice.call(nodeList);
    }

    this.bars = [];

    this.makeBars = function () {

        for (var b in be.progressBar.bars) {
            var el = be.progressBar.bars[b].el;
            var rect = el.getBoundingClientRect();
            var value = be.progressBar.bars[b].value;

            if (!be.progressBar.bars[b].loaded && rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
                be.progressBar.bars[b].bar.animate(value);
                be.progressBar.bars[b].loaded = true;
            }
        }

    }

    this.init = function () {
        
        // progressbar.js@1.0.0 version is used
        // Docs: http://progressbarjs.readthedocs.org/en/1.0.0/
        
        var progressBars = toArray(document.querySelectorAll('.progress-bar'));
        if (progressBars !== null) {

            progressBars.forEach(function (el) {

                var value = el.getAttribute("data-value") * 0.01; // Number from 0.0 to 1.0
                var rect = el.getBoundingClientRect();

                var bar = new ProgressBar.Line(el, {
                    strokeWidth: 0.25,
                    easing: 'easeInOut',
                    duration: 1500,
                    color: '#d1681a',
                    trailColor: '#363636',
                    trailWidth: 0.25,
                    svgStyle: { width: '100%', height: '100%' },
                    text: {
                        style: {
                            color: '#363636',
                            position: 'absolute',
                            right: '0',
                            top: '-20px',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            padding: 0,
                            margin: 0,
                            transform: null
                        },
                        autoStyleContainer: false
                    },
                    from: { color: '#fcc73a' },
                    to: { color: '#d1681a' },
                    step: function step(state, bar) {
                        bar.setText(Math.round(bar.value() * 100) + ' %');
                    }
                });
                var bar_object = {
                    "bar": bar,
                    "el": el,
                    "value": value,
                    "loaded" : false
                }

                be.progressBar.bars.push(bar_object);
                be.progressBar.makeBars();

            });

        }
        
        
    }

};

