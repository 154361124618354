var heyday = heyday || {};

heyday.partners = new function () {
    this.init = function () {
        const wrapperElement = document.querySelector(".wrapper");
        if (wrapperElement !== null) {
            const selectBtn = wrapperElement.querySelector(".select-btn");

            selectBtn.addEventListener("click", function () {
                wrapper.classList.toggle("active");
            });
        }
    };
};

