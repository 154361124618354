var be = be || {};

be.counter = new function () {

    this.init = function () {

        countUpInVieport();
        
    }

    function countUpInVieport() {

        //When in viewport
        if ($('.counter--animated').length) {
            $('.counter--animated').each(function () {

                var $current = $(this);
                if ($current.visible(true) && !$current.hasClass('counter--loaded')) {
                    $current.addClass('counter--loaded');

                    $(this).find('.counter__value').each(function () {
                        $(this).prop('Counter', 0).animate({
                            Counter: $(this).text()
                        }, {
                            duration: 1500,
                            easing: 'swing',
                            step: function (now) {
                                $(this).text(Math.ceil(now));
                            },
                            complete: function () {
                                $(this).parent().find('.counter__text').addClass('counter__text--loaded');
                                $(this).parent().find('.counter__description').addClass('counter__description--loaded');
                            }
                        });
                    });
                    
                }

            });
        }

    }
};

