var be = be || {};

/**
    Isotope wrapper for sorting contacts in list view.
**/

be.isotopeContactWrapper = new function () {
    var grid = document.querySelector('.module-contacts__grid');
    if (grid !== null) {
        var iso = new Isotope(grid, {
            // options...
            itemSelector: '.grid-item',
            percentPosition: true,
            masonry: {
                columnWidth: '.grid-sizer'
            }
        });
        
        // bind filter button click
        var filtersElem = document.querySelector('.filters-button-group');

        filtersElem.addEventListener('click', function (event) {
            // only work with buttons
            if (!matchesSelector(event.target, 'button')) {
                return;
            }
            var filterValue = event.target.getAttribute('data-filter');
            // use matching filter function
            filterValue = filterFns[filterValue] || filterValue;
            iso.arrange({
                filter: filterValue
            });
        });

        var categories = [
            'Executive management team',
            'Capital & Strategy',
            'Legal & Compliance',
            'Group Support',
            'Communications & Human Capital',
            'Product & Sales',
            'Sustainable business & public affairs',
            'Markets & investors',
            'Project Development & Project Finance',
            'Technology & Solutions',
            'Power Production & Asset Management'
        ];

        var categoryWrapper = function (itemElem, index) {
            var className = itemElem.classList.contains(categories[index].split(" ")[0].toLowerCase());
            return className;
        }

        // filter functions
        // itemElem is auto defined by isotope
        // Isotope requires this syntax.
        var filterFns = {
            executivegroup: function (itemElem) {
                return categoryWrapper(itemElem, 0);
            },
            capitalgroup: function (itemElem) {
                return categoryWrapper(itemElem, 1);
            },
            legalgroup: function (itemElem) {
                return categoryWrapper(itemElem, 2);
            },
            groupgroup: function (itemElem) {
                return categoryWrapper(itemElem, 3);
            },
            communicationsgroup: function (itemElem) {
                return categoryWrapper(itemElem, 4);
            },
            productgroup: function (itemElem) {
                return categoryWrapper(itemElem, 5);
            },
            sustainablegroup: function (itemElem) {
                return categoryWrapper(itemElem, 6);
            },
            marketsgroup: function (itemElem) {
                return categoryWrapper(itemElem, 7);
            },
            projectgroup: function (itemElem) {
                return categoryWrapper(itemElem, 8);
            },
            technologygroup: function (itemElem) {
                return categoryWrapper(itemElem, 9);
            },
            powergroup: function (itemElem) {
                return categoryWrapper(itemElem, 10);
            }
        };

        // change active class on buttons
        var buttonGroups = document.querySelectorAll('.filters-button-group');
        for (var i = 0, len = buttonGroups.length; i < len; i++) {
            var buttonGroup = buttonGroups[i];
            radioButtonGroup(buttonGroup);
        }
    }

    function radioButtonGroup(buttonGroup) {
        buttonGroup.addEventListener('click', function (event) {

            // only work with buttons
            if (!matchesSelector(event.target, 'button')) {
                return;
            }

            var contactsNav = document.querySelector('.module-contacts-nav');
            var contactsHeadline = document.querySelector('.module-contacts-nav__headline');
            var contactsHeadlineDefault = contactsHeadline.getAttribute('data-text');
            var contactsActiveButton = event.target;
            var contactsHeadlineCurrent = contactsActiveButton.getAttribute('data-category');
            var contactsBgCurrent = contactsActiveButton.getAttribute('data-bg');
            var contactsBgDefault = contactsNav.getAttribute('data-bg');

            if (event.target.classList.contains('active')) {
                event.target.classList.remove('active');
                iso.arrange({
                    filter: '*'
                });

                contactsNav.style.backgroundImage = "url('" + contactsBgDefault + "')";
                contactsHeadline.classList.remove('loaded');
                contactsHeadline.textContent = contactsHeadlineDefault;
                setTimeout(function () {
                    contactsHeadline.classList.add('loaded');
                }, 300);
            } else {

                var btnElems = buttonGroup.querySelector('.active')
                if (btnElems) {
                    btnElems.classList.remove('active');
                }
                event.target.classList.add('active');

                contactsNav.style.backgroundImage = "url('" + contactsBgCurrent + "')";
                contactsHeadline.classList.remove('loaded');
                contactsHeadline.textContent = contactsHeadlineCurrent;
                setTimeout(function () {
                    contactsHeadline.classList.add('loaded');
                }, 300);

            }
        });
    }
    this.init = function () {
        try {
            if (iso !== null) {
                // Show all as isotope default
                iso.arrange({
                    filter: '*'
                });
            }
        } catch (error) {
            // do nothing 
        }
    };
}