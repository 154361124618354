/* Heyday 09/2018 - by Anto
********************************************************************/

var heyday = heyday || {};
var be = be || {};

// Document ready
$(function () {
    heyday.lazyload.lazyloadMedia('image');
    be.header.init();
    be.onePager.init();
    be.mouseAnimate.init();
    be.scrollAnimate.init();
    be.counter.init();
    be.owlCarousel.init();
    be.popUpBox.init();
    be.navSearch.init();
    be.perScrollbar.init();
    be.gridSection.init();
    heyday.accordion.init();
    heyday.partners.init();

}); // Document ready end


$(window).load(function () {    

    heyday.lazyload.lazyloadMedia('image');
    heyday.lazyload.lazyloadMedia('video');
    heyday.lazyload.lazyloadMedia('background');
 
    heyday.animate.setAnimation();
    be.counter.init();
    heyday.partners.init();
    be.progressBar.init();
    if (be && be.navigation) {
        be.navigation.init();

    }
    be.map.init();
    be.infoWheel.init();
    be.isotopeContactWrapper.init();
});


/*
    resize & scroll are using the timing function in 
    order to reduce the amount of event requests
*/
$(window).resize(heyday.timing.debounce(function () {

    heyday.lazyload.lazyloadMedia('image');
    heyday.lazyload.lazyloadMedia('video');
    heyday.lazyload.lazyloadMedia('background');
    heyday.animate.setAnimation();
    be.counter.init();
    be.map.init();
    be.navigation.init();

}, 50));


$(window).scroll(heyday.timing.debounce(function () { 

    heyday.animate.setAnimation();
    be.counter.init();
    be.progressBar.makeBars();
    be.onePager.activeDotPosition();

}, 50));