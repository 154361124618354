var be = be || {};

/**
    Pop up box
**/

be.popUpBox = new function () {

    this.init = function () {
        
        var poBox = document.querySelector('.pop-up-box');

        if (poBox !== null) {
            
            var poBoxToggleBtn = poBox.querySelector('.pop-up-box__toggle-btn');
            var poBoxCloseBtn = poBox.querySelector('.pop-up-box__close-btn');

            //1. If not 'popUpBoxVisibilityClosed' cookie exists, make the box visible
            if (!Cookies.get('popUpBoxVisibilityClosed')) {
                poBox.classList.add('pop-up-box--visible');
            }

            //2. Close, if box is visible, add a 'closePopUpBox' cookie, to remember is closed
            poBoxCloseBtn.addEventListener('click', function (e) {
                e.preventDefault();
                poBox.classList.remove('pop-up-box--visible');
                Cookies.set('popUpBoxVisibilityClosed', 'popUpBoxVisibility--closed', { expires: 1 });
            });

            //3. Toggle box visibility 
            poBoxToggleBtn.addEventListener('click', function (e) {
                e.preventDefault();
                poBox.classList.toggle('pop-up-box--closed');
            });

            //4. Remove 'popUpBoxVisibilityClosed' cookie (optional)
            //Cookies.remove('popUpBoxVisibilityClosed');

        }
        
    }

};








