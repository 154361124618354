var be = be || {};

/**
    Mouse Animate
**/

be.mouseAnimate = new function () {

    this.onePagerBackground = function () {

        var onePager = document.querySelector('.one-pager');

        if (onePager !== null) {

            var lFollowX = 0,
                lFollowY = 0,
                x = 0,
                y = 0,
                friction = 1 / 30;

            function moveBackground() {

                x += (lFollowX - x) * friction;
                y += (lFollowY - y) * friction;

                translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

                $('.one-pager__section--visible .banner-2-col__figure--background').css({
                    '-webit-transform': translate,
                    '-moz-transform': translate,
                    'transform': translate
                });

                window.requestAnimationFrame(moveBackground);
            }

            $('.one-pager').on('mousemove', function (e) {

                var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
                var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
                lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
                lFollowY = (10 * lMouseY) / 100;

            });

            moveBackground();
        }

    }

    this.onePagerForeground = function () {

        var onePager = document.querySelector('.one-pager');

        if (onePager !== null) {

            var lFollowX = 0,
                lFollowY = 0,
                x = 0,
                y = 0,
                friction = 1 / 15;

            function moveForeground() {

                x += (lFollowX - x) * friction;
                y += (lFollowY - y) * friction;

                translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

                $('.one-pager__section--visible .banner-2-col__figure--foreground').css({
                    '-webit-transform': translate,
                    '-moz-transform': translate,
                    'transform': translate
                });

                window.requestAnimationFrame(moveForeground);
            }

            $('.one-pager').on('mousemove', function (e) {

                var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
                var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
                lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
                lFollowY = (10 * lMouseY) / 100;

            });

            moveForeground();
        }

    }

    this.init = function () {
        
        be.mouseAnimate.onePagerBackground();
        be.mouseAnimate.onePagerForeground();
        
    }

};








