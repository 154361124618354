var activeInfoBoxId = "01";
var infoboxCount = document.querySelectorAll('div[id*="info-box__point-"]');

function updateCounter() {
    var infoMap = document.getElementById("info-map__counter");
    if (infoMap !== null) {
        infoMap.innerHTML = parseInt(activeInfoBoxId) + "/7";
    }
}

document.addEventListener('DOMContentLoaded', function () {
    updateCounter();
    setActiveInfoBox(activeInfoBoxId);
}, false);

function setActiveInfoBox(id) {
    activeInfoBoxId = id;
    hideAll();
    showActiveInfoBox();
    updateCounter();
}

function showActiveInfoBox() {
    var infobox = document.getElementById("info-box__text-" + activeInfoBoxId);
    var infoboxBtn = document.getElementById("info-box__point-btn-" + activeInfoBoxId);

    if (infoboxBtn && !infoboxBtn.classList.contains("active")) {
        infoboxBtn.classList.add("active");
    }

    if (infobox && infobox.classList.contains("noshow")) {
        infobox.classList.remove("noshow");
    }
}

function hideAll() {
    var infoboxes = document.querySelectorAll(".info-box-seg");
    var infoboxesBtns = document.querySelectorAll(".info-box__point-btn");
    for (var i = 0; i < infoboxes.length; i++) {
        if (!infoboxes[i].classList.contains("noshow")) {
            infoboxes[i].classList.add("noshow");
        }
    }
    for (var j = 0; j < infoboxesBtns.length; j++) {
        if (infoboxesBtns[j].classList.contains("active")) {
            infoboxesBtns[j].classList.remove("active");
        }
    }
}

function prevInfoBox() {
    if (activeInfoBoxId == "01") {
        setActiveInfoBox("0" + infoboxCount.length);
    } else {
        setActiveInfoBox("0" + (parseInt(activeInfoBoxId) - 1));
    }
}

function nextInfoBox() {    
    if (infoboxCount.length == parseInt(activeInfoBoxId)) {
        setActiveInfoBox("01");
    } else {
        setActiveInfoBox("0" + (parseInt(activeInfoBoxId) + 1));
    }
}