var heyday = heyday || {};

/**
    These function allows for the lazyloading of images, videos & background images,
    relative to the current breakpoint.

    This can also be implemented as progressive lazyloading by 
    adding these to the resize & scroll events.
**/

heyday.lazyload = new function () {

    this.lazyloadMedia = function (type) {
        if ($('.lazyload').length) {

            $('.lazyload').each(function () {
                var $current = $(this);
                var src = $current.data('src');
                var srcMd = src;
                var srcSm = src;
                var srcMs = src;
                var srcXs = src; 

                var width = $(window).innerWidth();

                if ($current.attr('data-src-md')) {
                    srcMd = $current.data('src-md');
                }

                if ($current.attr('data-src-sm')) {
                    srcSm = $current.data('src-sm');
                }

                if ($current.attr('data-src-ms')) { 
                    srcMs = $current.data('src-ms');
                }

                if ($current.attr('data-src-xs')) {
                    srcXs = $current.data('src-xs'); 
                }

                var mediaSrc = src;

                if (width > 0 && width < 480 && srcXs != src) {
                    mediaSrc = srcXs;
                }

                if (width > 480 && width < 768 && srcMs != src) {
                    mediaSrc = srcMs;
                }
                if (width >= 768 && width < 992 && srcSm != src) {
                    mediaSrc = srcSm;
                }
                if (width >= 992 && width < 1200 && srcMd != src) {
                    mediaSrc = srcMd;
                }
                if (width >= 1200) {
                    //mediaSrc = src;
                }

                if($current.hasClass('lazyload--image') && type == "image") {
                    $current.prop('src', mediaSrc);
                }
                
                if($current.hasClass('lazyload--background') && type == "background") {
                    $current.css('background-image', 'url(' + mediaSrc + ')');
                }

                if($current.hasClass('lazyload--video') && type == "video") {
                    $current.find('source').prop('src', mediaSrc);
                    $current.load();
                }
            });
        }
    }
};








