/**
    This function kan be used to detect touch devices.
    Please note that this is also including Ipad tablets, 
    so if you only want to target small mobile devides, you
    need to adjust iOS and exclude iPad.


    Usage:
    if(isTouch.any()) {
        console.log('This is a touch device');
    } else {
        console.log('This is NOT a touch device');
    }
**/

var isTouch = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isTouch.Android() || isTouch.BlackBerry() || isTouch.iOS() || isTouch.Opera() || isTouch.Windows());
    }
}

