var be = be || {};

be.perScrollbar = new function () {

    this.init = function () {


        //'module-news__ul--search-result'

        const ps = new PerfectScrollbar('.module-news__ul--search-result', {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
        });
        
    }
};

