var heyday = heyday || {};

heyday.accordion = new function () {
    this.init = function (el) {
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("accordion--active");
            });
        }
    };
};