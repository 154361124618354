var heyday = heyday || {};

/**
    This function kan be used with events that fire multible
    times (resize/scroll), in order to avoid triggering an
    event overload

    Usage eksample:
    $(window).resize(heyday.timing.debounce(function () {
        hummel.lazyload.init();
    }, 50));

    In the eksample above "hummel.lazyload.init();" will only fire
    when it has not recived a new resize event for at least
    50ms
**/

heyday.timing = new function () {
    this.debounce = function (fn, delay) {
        var timer = null;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args);
            }, delay);
        };
    }
}