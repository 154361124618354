var heyday = heyday || {};

/**
    This script works with "animate.less" and will trigger 
    animations on elements when they come into view.
**/

heyday.animate = new function () {
    this.setAnimation = function () {
        animate('.fade-in');
        animate('.fade-in-left');
        animate('.fade-in-right');
        animate('.fade-in-up');
        animate('.fade-in-down');     
        animate('.dash');
        animate('.big-dash');
        animate('.module-footer-banners');
        animate('.module-image');
    }

    function animate(element) {
        if ($(element).length) {
            $(element).each(function () {
                var $current = $(this);
                if ($current.visible(true) && !$current.hasClass('loaded')) {
                    $current.addClass('loaded');
                }
            });
        }
    }
};








