var be = be || {};

/**
    One Pager
**/

be.onePager = new function () {

    const op = document.querySelector('.one-pager');

    this.sectionisAnimating = false;

    function toArray(nodeList) {
        return Array.prototype.slice.call(nodeList);
    }
    
    this.activeDotPosition = function (newEl) {

        var onePager = document.querySelector('.one-pager'); 
        if (onePager !== null) {

            var onePagerNavi = onePager.querySelector('.navigation-one-pager'),
            onePagerNaviPos = onePagerNavi.getBoundingClientRect().top,
            onePagerNaviItems = toArray(onePagerNavi.querySelectorAll('.navigation__anchor')),
            onePagerNaviActiveDot = onePagerNavi.querySelector('.navigation__active-dot'),
            onePagerNaviActiveAnchorCircle = onePagerNavi.querySelector('.navigation__anchor--active > .navigation__anchor-circle');

            //1. Set dot�s default location
            if (onePagerNaviActiveAnchorCircle !== null && newEl == null) {

                var onePagerNaviActiveAnchorCirclePos = onePagerNaviActiveAnchorCircle.getBoundingClientRect().top;
                var newActiveDotPos = Math.round(onePagerNaviActiveAnchorCirclePos - onePagerNaviPos) + 'px';
                onePagerNaviActiveDot.style.transform = "translateY(" + newActiveDotPos + ")";

            }
            else if (newEl != null) {

                var newElCirclePos = newEl.querySelector('.navigation__anchor-circle').getBoundingClientRect().top;
                var newActiveDotPos = Math.round(newElCirclePos - onePagerNaviPos) + 'px';
                onePagerNaviActiveDot.style.transform = "translateY(" + newActiveDotPos + ")";

            }

            //2. On item hover, move the active dot
            onePagerNaviItems.forEach(function (el) {

                el.addEventListener("mouseenter", function () {

                    var thisCirclePos = el.querySelector('.navigation__anchor-circle').getBoundingClientRect().top,
                        newActiveDotPos = Math.round(thisCirclePos - onePagerNaviPos) + 'px';

                    onePagerNaviActiveDot.style.transform = "translateY(" + newActiveDotPos + ")";

                });

            });

            //3. Return dot�s to the default location if leaving navigation
            onePagerNavi.addEventListener("mouseleave", function () {
                be.onePager.activeDotPosition();
            });

        }
        
    }

    this.animateCounter = function (activeSection) {
        
        if (activeSection == 1) {
            
            const counter = op.querySelector('.counter');

            if (counter !== null) {
                const counterItems = op.querySelectorAll('.counter__value');
                counter.classList.add('counter--loaded');

                counterItems.forEach(function (el) {

                    $(el).prop('Counter', 0).animate({
                        Counter: $(el).text()
                    },
                    {
                        duration: 1500,
                        easing: 'swing',
                        step: function (now) {
                            $(el).text(Math.ceil(now));
                        },
                        complete: function () {
                            $(el).parent().find('.counter__text').addClass('counter__text--loaded');
                            $(el).parent().find('.counter__description').addClass('counter__description--loaded');
                        }
                    });
                    
                });
                
            }
            


        }
    }

    this.activateSection = function (activeSection) {
        //This function is meant to be called from The navigation anchor, the next section button or mousewheel event

        var onePager = document.querySelector('.one-pager'),
            onePagerSection = toArray(onePager.querySelectorAll('.one-pager__section')),
            onePagerNavAnchors = toArray(onePager.querySelectorAll('.one-pager__navigation .navigation__anchor')),
            onePagerBtnNext = onePager.querySelector('.btn-scroll--next-story');

        onePagerSection.forEach(function (el) {

            var onePagerSectionIndex = el.getAttribute('data-index');

            if (onePagerSectionIndex == activeSection) {

                //Activate a section
                be.onePager.sectionisAnimating = true;
                el.style.zIndex = "1";
                el.classList.add('one-pager__section--visible');
                setTimeout(function () {
                    be.onePager.sectionisAnimating = false;
                }, 1000);

                //Counter
                be.onePager.animateCounter(activeSection);

            }
            else {

                //Deactive the siblings sections, if visible
                if (el.classList.contains('one-pager__section--visible')) {
                    el.style.zIndex = "0";

                    setTimeout(function () {
                        el.classList.remove('one-pager__section--visible');
                    }, 1000);

                }

            }

        });

        onePagerNavAnchors.forEach(function (el) {

            var onePagerAnchorIndex = el.getAttribute('data-index');
            if (onePagerAnchorIndex == activeSection) {

                //Activate an anchor
                el.classList.add('navigation__anchor--active');

                //Relocate active dot
                be.onePager.activeDotPosition(el);

                var scrollBtn = document.querySelector(".btn-scroll__text");
                if(onePagerAnchorIndex == "0"){
                    scrollBtn.innerText = "Read our story";   
                }else if(onePagerNavAnchors.length-1 ==  parseInt(onePagerAnchorIndex))
                {
                    scrollBtn.innerText = "Back to top";
                }else{
                    scrollBtn.innerText = "Next storyline";  
                }
            }
            else {

                //Deactive the siblings anchors
                el.classList.remove('navigation__anchor--active');

            }

        });

    }

    this.navigationControl = function () {

        var onePager = document.querySelector('.one-pager');

        if (onePager !== null) {

            var onePagerSection = toArray(onePager.querySelectorAll('.one-pager__section')),
                onePagerFirstChild = onePager.querySelector('.one-pager__section:first-child'),
                onePagerNavUlFirstChild = onePager.querySelector('.one-pager__nav-ul li:first-child > a'),
                onePagerBtnNext = onePager.querySelector('.btn-scroll--next-story'),
                onePagerNavUl = onePager.querySelector('.navigation__ul'),
                onePagerNavAnchors = toArray(onePager.querySelectorAll('.navigation__anchor')),
                onePagerActiveIndex = 0;

            //1. Make the first section active
            be.onePager.activateSection(onePagerActiveIndex);

            //2. On navigation anchor click, active a new section
            onePagerNavAnchors.forEach(function (el) {
                el.addEventListener('click', function (e) {
                    e.preventDefault();
                    
                    if (be.onePager.sectionisAnimating) {
                        return false;
                    }

                    var item = this,
                        itemIndex = el.getAttribute('data-index'),
                        itemHref = el.getAttribute('href'),
                        sectionId = itemHref.split('#')[1],
                        currentSection = document.getElementById(sectionId),
                        currentSectionSiblings = toArray(currentSection.parentElement.children).filter(function (node) { return node !== currentSection; }),
                        itemAnchorSiblings = toArray(item.parentElement.parentElement.children).filter(function (node) { return node !== item.parentNode; });

                    onePagerActiveIndex = itemIndex;
                    be.onePager.activateSection(onePagerActiveIndex);

                });
            });

            //3. onePagerBtnNext, on click updated the onePagerActiveIndex & move the active dot
            onePagerBtnNext.addEventListener('click', function (e) {
                e.preventDefault();

                if (onePagerActiveIndex < (onePagerSection.length - 1)) {

                    onePagerActiveIndex++;
                    be.onePager.activateSection(onePagerActiveIndex);

                }
                else {

                    onePagerActiveIndex = 0;
                    be.onePager.activateSection(onePagerActiveIndex);
                }

            });

            //4. On mousewheel down show the next section, up show the prevoius section

                //We can now attach the mousewheel event handler:
                if (onePager.addEventListener) {

                    // IE9, Chrome, Safari, Opera
                    onePager.addEventListener("mousewheel", MouseWheelHandler, false);
                    // Firefox
                    onePager.addEventListener("DOMMouseScroll", MouseWheelHandler, false);

                }

                function MouseWheelHandler(e) {

                    // cross-browser wheel delta
                    var e = window.event || e; // old IE support
                    var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
                    
                    //console.log('e', e);
                    //console.log('delta', delta);

                    if (delta == -1) {
                        //Mousewheel down

                        if (onePagerActiveIndex < (onePagerSection.length - 1)) {
                            if (be.onePager.sectionisAnimating) {
                                return false;
                            }
                            onePagerActiveIndex++;
                            be.onePager.activateSection(onePagerActiveIndex);
                        }
                        else {
                            if (be.onePager.sectionisAnimating) {
                                return false;
                            }
                            onePagerActiveIndex = 0;
                            be.onePager.activateSection(onePagerActiveIndex);
                        }

                    }
                    else if (delta == 1) {
                        //Mousewheel up

                        if (onePagerActiveIndex > 0) {
                            if (be.onePager.sectionisAnimating) {
                                return false;
                            }
                            onePagerActiveIndex--;
                            be.onePager.activateSection(onePagerActiveIndex);
                        }
                        else {
                            if (be.onePager.sectionisAnimating) {
                                return false;
                            }
                            onePagerActiveIndex = 4;
                            be.onePager.activateSection(onePagerActiveIndex);
                        }

                    }
                    
                    return false; //cancel the standard event which would normally scroll the page
                }


        }

    }

    this.init = function () {

        be.onePager.navigationControl();
        
    }

};


