var be = be || {};

/**
    Owl slider init
**/

be.owlCarousel = new function () {

    this.init = function () {

        if ($('.owl-carousel').length) {

            $('.owl-carousel').owlCarousel({
                loop: true,
                margin: 0,
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true
                    }
                }
            })

        }

        if ($('.owl-carousel--4').length) {

            $('.owl-carousel--4').owlCarousel({
                loop: true,
                margin: 0,
                responsiveClass: true,
                responsive: {
                    // breakpoint from 0 up
                    0: {
                        items: 1,
                        nav: false, 
                        dots: true
                    },
                    // breakpoint from 480 up
                    480: {
                        items: 2,
                        nav: false,
                        dots: true
                    },
                    // breakpoint from 768 up
                    768: {
                        items: 3,
                        nav: false,
                        dots: true
                    },
                    // breakpoint from 1024 up
                    1024: {
                        items: 4,
                        nav: false,
                        dots: true
                    },
                    // breakpoint from 1280 up
                    1280: {
                        items: 4,
                        nav: true,
                        dots: false
                    }
                }
            })

        }

        
    }

};








