var be = be || {};

be.navSearch = new function () {

    this.init = function () {

        // Search from menu
        $(".main-nav__search").submit(function (evt) {
            evt.preventDefault();
            var q = $(this).find("[type=search]").val();

            // Execute search
            $.ajax(
                {
                    method: "POST",
                    url: "/umbraco/api/Search/PagesAndNews?q=" + q
                })
                .done(displayMenuResults);

            // Although search is (probably) still going, show result page. It gives a nice snappy feel
            $('.search-result--overlay').addClass('active');

            return false;
        });
        
        // Search from news
        if ($(".search-news-form").length) {
            $(".search-news-form").submit(function (evt) {
                evt.preventDefault();

                var q = $(this).find("[type=search]").val();

                $.ajax(
                    {
                        method: "POST",
                        url: "/umbraco/api/Search/PagesAndNews?q=" + q
                    })
                    .done(function (data) { displayNewsResults(data, $(".module-news .module-news__ul"), "blue") });

                // Change the headline from "Latest news" to something more relevant for a search
                $(".module-news .headline").text("Results for '" + q + "'");

                return false;
            });
        }

        // Setups up search in menu
        function displayMenuResults(data) {

            $newsResults = $(".module-news__ul--search-result");
            $pagesResults = $(".module-pages__ul--search-result");

            // Reset any existing searches
            $pagesResults.html("");

            if (data.pages.length === 0)
                $pagesResults.append("<li>No results</li>");

            // Show results for news
            displayNewsResults(data, $newsResults, "orange");

            // Show results for pages
            displayPagesResult(data, $pagesResults);
        }

        // Render a container with news search results
        function displayNewsResults(data, $newsResults, themeColor) {

            // Check if result-container is available
            if (!$newsResults.length)
                return;

            // Reset any existing searches
            $newsResults.html("");

            if (data.news.length === 0)
                $newsResults.append("<li>No results</li>");

            // Render news items
            for (var i in data.news) {
                var newsItem = data.news[i];

                $newsResults.append(
                    '<li class="module-news__li">' +
                    '<div class="module-news__item">' +
                    '<div class="row">' +
                    '<div class="col-ms-4 col-sm-3">' +
                    '<a class="module-news__item-image" href="' + newsItem.url + '">' +
                    '<img class="content" src="' + newsItem.image + '" />' +
                    '</a>' +
                    '</div>' +
                    '<div class="col-ms-8 col-sm-9">' +
                    '<div class="module-news__item-text">' +
                    '<div class="text-area">' +
                    '<span class="module-news__item-text-date color--' + themeColor + '">' + newsItem.publishedDate + '</span>' +
                    '<h3 class="headline">' + newsItem.headline + '</h3>' +
                    '<a href="' + newsItem.url + '" class="btn btn--' + themeColor + '"><span class="btn__text">Read more</span></a>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div >' +
                    '</li >'
                );
            }
        }

        // Render a container with pages search results
        function displayPagesResult(data, $pagesResults) {
            
            for (var i in data.pages) {
                var pageItem = data.pages[i];
                var names = "";

                // Iterate path and reverse, so subpaths are marked as strong
                for (var i = pageItem.path.length - 1; i >= 0; i--) {
                    var path = pageItem.path[i];
                    if (i === 0)
                        names += path;
                    else
                        names += "<strong>" + path + "</strong> - ";
                }

                $pagesResults.append(
                    '<li class="search-result__li">' +
                    '<a href="' + pageItem.url + '" class="search-result__anchor">' + names + '</a>' +
                    '</li>'
                );
            }
        }

    }
    
};