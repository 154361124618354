var be = be || {};

/**
    Header
**/

be.header = new function () {

    function toArray(nodeList) {
        return Array.prototype.slice.call(nodeList);
    }

    this.init = function () {

        const header = document.querySelector('.header');

        if (header !== null) {

            const body = document.querySelector('body');
            const burger = header.querySelector('.nav-burger');
            const navSearch = document.getElementById('nav-main--search-trigger');
            const navSearchInput = document.querySelector('.main-nav__search-input');
            const navMainItemParent = toArray(header.querySelectorAll('.main-nav__list-item--parent'));
            const navMainList = header.querySelector('.main-nav__list');
            const searchResult = document.querySelector('.search-result--overlay');
            const searchResultToggleBtn = searchResult.querySelector('.search-result__toggle-btn');

            //Animate burger on load
            setTimeout(function () { burger.classList.add("burger--loaded"); }, 700);

            // On .nav-burger toggle click add a .body--overlay-opened into the body, 
            //then close search result if opened
            burger.addEventListener('click', function () {

                body.classList.toggle('body--overlay-opened');

                if (navSearch.checked === true) {
                    navSearch.checked = false;
                }

                //Close search result, if opened
                if (searchResult !== null) {
                    searchResult.classList.remove('active');
                }

                //If a menu item has children open close them
                navMainItemParent.forEach(function (el) {
                    el.classList.remove('main-nav__list-item--parent-active');
                });

            });

            //Toggle search result
            searchResultToggleBtn.addEventListener('click', function (el) {
                searchResult.classList.remove('active');
                navSearchInput.value = '';
            });

            //Close search result on header click
            header.addEventListener('click', function () {
                searchResult.classList.remove('active');
            });

            

        }

    };

};








