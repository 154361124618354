var be = be || {};

/**
    Scroll animate
**/

be.scrollAnimate = new function () {

    /*function isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    }
    */

    this.btnScroll = function () {

        //https://stackoverflow.com/questions/29760049/scroll-to-next-section

        var pagePositon = 0,
            sectionsSelector = 'section',
            $scrollItems = $(sectionsSelector),
            offsetTolerance = 30,
            pageMaxPosition = $scrollItems.length - 1;

        //Map the sections:
        $scrollItems.each(function (index, ele) {
            $(ele).attr("debog", index).data("pos", index);
        });

        // Bind to scroll
        $(window).bind('scroll', upPos);

        //Move on click:
        $('.btn-scroll').click(function (e) {

            if ($(this).hasClass('btn-scroll--next') && pagePositon + 1 <= pageMaxPosition) {
                pagePositon++;
                $('html, body').stop().animate({
                    scrollTop: $scrollItems.eq(pagePositon).offset().top
                }, 400);
            }

            if ($(this).hasClass('btn-scroll--prev') && pagePositon - 1 >= 0) {
                pagePositon--;
                $('html, body').stop().animate({
                    scrollTop: $scrollItems.eq(pagePositon).offset().top
                }, 400);
                return false;
            }

        });

        //Update position func
        function upPos() {

            var fromTop = $(this).scrollTop();
            var $cur = null;

            $scrollItems.each(function (index, ele) {
                if ($(ele).offset().top < fromTop + offsetTolerance) {
                    $cur = $(ele);
                }
            });

            if ($cur != null && pagePositon != $cur.data('pos')) {
                pagePositon = $cur.data('pos');
            }

        }
    }

    this.btn = function () {

        //Button Scroll
        //https://stackoverflow.com/questions/29760049/scroll-to-next-section

        var pagePositon = 0,
            sectionsSelector = 'section',
            $scrollItems = $(sectionsSelector),
            offsetTolerance = 30,
            pageMaxPosition = $scrollItems.length - 1;

        //Map the sections:
        $scrollItems.each(function (index, ele) { $(ele).attr("debog", index).data("pos", index); });

        // Bind to scroll
        //$(window).bind('scroll', upPos);

        //Move on click:
        $('.btn-scroll').click(function (e) {

            if ($(this).hasClass('btn-scroll--next') && pagePositon + 1 <= pageMaxPosition) {
                pagePositon++;
                $('html, body').stop().animate({
                    scrollTop: $scrollItems.eq(pagePositon).offset().top
                }, 400);
            }

            if ($(this).hasClass('btn-scroll--prev') && pagePositon - 1 >= 0) {
                pagePositon--;
                $('html, body').stop().animate({
                    scrollTop: $scrollItems.eq(pagePositon).offset().top
                }, 400);
                return false;
            }

        });

        //Update position func
        function upPos() {

            var fromTop = $(this).scrollTop();
            var $cur = null;

            $scrollItems.each(function (index, ele) {
                if ($(ele).offset().top < fromTop + offsetTolerance) {
                    $cur = $(ele);
                }
            });

            if ($cur != null && pagePositon != $cur.data('pos')) {
                pagePositon = $cur.data('pos');
            }

        }

        function debounce(fn, threshold, debounceHandler)
        {
            var lastTime = 0;
            return function ()
            {
                var now = Date.now();
                if (now - lastTime > threshold)
                {
                    lastTime = now;
                    fn.apply(window, Array.prototype.slice.call(arguments));
                }
                else
                {
                    if (debounceHandler)
                    {
                        debounceHandler.apply(window, Array.prototype.slice.call(arguments));
                    }
                }
            }
        }

        //Move on scroll:
        var lastScrollY = window.scrollY;
        var pagePosition = 0;
        window.onwheel = debounce(function (e) {

            if (window.scrollY >= 0 && window.scrollY < window.innerHeight * 0.99 && e.deltaY > 0)
            {
                if (document.querySelector("main").children.length > 0)
                {
                    e.preventDefault();
                    $('html, body').stop().animate({
                        scrollTop: document.querySelector("main").children[1].offsetTop
                    }, 400);
                }
            }

            if (window.scrollY >= window.innerHeight * 0.9 && window.scrollY <= window.innerHeight * 1.99 && e.deltaY < 0) {
                // if scrolling posiiton is larger than the screenheight (90%) and lower than two screen heights (by 10%) 
                // and we are scrolling up on the wheel
                if (document.querySelector("main").children.length > 0) {
                    e.preventDefault();
                    $('html, body').stop().animate({
                        scrollTop: 0
                    }, 400);
                }
            }

            if (window.scrollY >= window.innerHeight * 1.01 && window.scrollY < window.innerHeight * 2 && e.deltaY < 0) {
                if (document.querySelector("main").children.length > 0) {
                    e.preventDefault();
                    $('html, body').stop().animate({
                        scrollTop: document.querySelector("main").children[1].offsetTop
                    }, 400);
                }
            }

            if (window.scrollY >= window.innerHeight * 0.9 && window.scrollY <= window.innerHeight * 1.99 && e.deltaY > 0) {
                // if scrolling posiiton is larger than the screenheight (90%) and lower than two screen heights (by 10%) 
                // and we are scrolling up on the wheel
                if (document.querySelector("main").children.length > 1) {
                    e.preventDefault();
                    $('html, body').stop().animate({
                        scrollTop: document.querySelector("main").children[2].offsetTop
                    }, 400);
                }
            }


            
            //e.preventDefault();


            //if (direction === 0)
            //    return;

            //if (direction === 1)
            //{
            //    // scroll down
            //    pagePositon = Math.min(pagePosition + 1, pageMaxPosition);
            //}
            //else if (direction === -1)
            //{
            //    // scroll up
            //    pagePositon = Math.max(pagePosition - 1, 0);
            //}
            //console.log(pagePosition, pageMaxPosition, direction, $scrollItems.eq(pagePositon).offset().top);

            //$('html, body').stop().animate({
            //    scrollTop: $scrollItems.eq(pagePositon).offset().top
            //}, 400);

            //lastScrollY = $scrollItems.eq(pagePositon).offset().top;

        }, 200, function (e) { (window.scrollY >= 0 && window.scrollY < window.innerHeight * 0.5 && e.deltaY > 0) && e.preventDefault(); });

    }

    this.synchronized = function () {

        //Synchronize animation to the scrollbar movement (like a playback scrub control)
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;

        var footerBannersRow = document.querySelector('.module-footer-banners__row');
        var footerBannersRowRect = footerBannersRow.getBoundingClientRect();
        var footerBannersRowRectHeight = footerBannersRowRect.height;
        var footerBannersRowItemLeft = footerBannersRow.querySelector('.module-footer-banners__item-left');
        var footerBannersRowItemRight = footerBannersRow.querySelector('.module-footer-banners__item-right');
        
        //document.getElementById("myBar").style.width = scrolled + "%";
        
        if (isElementInViewport(footerBannersRow)) {

            console.log('---IN viewport---');
            console.log('footerBannersRowRect', footerBannersRowRect);
            console.log('footerBannersRowRectHeight', footerBannersRowRectHeight);
            //console.log(footerBannersRowRect.top / footerBannersRowRect.height);
            //var scrollPerc = footerBannersRowRect.top / footerBannersRowRect.height;
            //var goto = scrollPerc.toFixed(2) * 100;
            //var xval = scrollPerc;
            //console.log(goto);
            //console.log(xval);
            //console.log('winScroll', winScroll);
            //console.log('height', height);
            //console.log('scrolled', scrolled);

            //If going DOWN

            //If UP

            footerBannersRowItemLeft.style.transform = "translate(0,0)";
            footerBannersRowItemRight.style.transform = "translate(0,0)";

        }
        else {
            console.log('---OUT of viewport---');

            footerBannersRowItemLeft.style.transform = "translate(0,-60px)";
            footerBannersRowItemRight.style.transform = "translate(0,60px)";

        }
        

    }

    this.init = function () {

        be.scrollAnimate.btnScroll();
    }

};








