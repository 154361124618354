var be = be || {};

/**
    Info wheel
**/

be.infoWheel = new function () {

    function toArray(nodeList) {
        return Array.prototype.slice.call(nodeList);
    }

    this.actions = function () {

        var wheel = document.querySelector('.info-wheel');
        if (wheel !== null) {
            var wheelActiveIndex = 1,
                seg1 = wheel.querySelector('.circle-segment-1'),
                seg1Transform = seg1.getAttribute("transform"),
                seg2 = wheel.querySelector('.circle-segment-2'),
                seg2Transform = seg2.getAttribute("transform"),
                seg3 = wheel.querySelector('.circle-segment-3'),
                seg3Transform = seg3.getAttribute("transform"),
                seg4 = wheel.querySelector('.circle-segment-4'),
                seg4Transform = seg4.getAttribute("transform"),
                seg5 = wheel.querySelector('.circle-segment-5'),
                seg5Transform = seg5.getAttribute("transform"),
                seg6 = wheel.querySelector('.circle-segment-6'),
                seg6Transform = seg6.getAttribute("transform"),
                hightlight = wheel.querySelector('.circle-highlight'),
                wheelPrevBtn = wheel.querySelector('.info-wheel__prev-btn'),
                wheelNextBtn = wheel.querySelector('.info-wheel__next-btn'),
                wheelInnerItems = toArray(wheel.querySelectorAll('.info-wheel__in-circle')),
                wheelInnerItemsLength = 6,//wheelInnerItems.length,
                wheelPrevActiveIndex,
                wheelPrevActiveIndexClass;
            
            
            function removePrevClass() {

                //previous active item class
                wheelPrevActiveIndex = wheelActiveIndex;
                wheelPrevActiveIndexClass = 'info-wheel--choice-' + wheelPrevActiveIndex;
                wheel.classList.remove(wheelPrevActiveIndexClass);

            }

            seg1.addEventListener('click', function (el) {
                
                removePrevClass();

                //new active item
                wheelActiveIndex = 1;
                wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);
            });

            seg2.addEventListener('click', function (el) {

                removePrevClass();

                //new active item
                wheelActiveIndex = 2;
                wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);
            });

            seg3.addEventListener('click', function (el) {

                removePrevClass();

                //new active item
                wheelActiveIndex = 3;
                wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);
            });

            seg4.addEventListener('click', function (el) {

                removePrevClass();

                //new active item
                wheelActiveIndex = 4;
                wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);
            });

            seg5.addEventListener('click', function (el) {

                removePrevClass();

                //new active item
                wheelActiveIndex = 5;
                wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);
            });

            seg6.addEventListener('click', function (el) {

                removePrevClass();

                //new active item
                wheelActiveIndex = 6;
                wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);
            });

            function goToPrev() {
                if (wheelActiveIndex > 1) {

                    removePrevClass();
                    wheelActiveIndex--;
                    clickThis(document.querySelector("#info-wheel__svg").querySelectorAll(".segment")[wheelActiveIndex - 1]);
                    wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);

                }
                else if (wheelActiveIndex == 1) {

                    removePrevClass();
                    wheelActiveIndex = 6;
                    clickThis(document.querySelector("#info-wheel__svg").querySelectorAll(".segment")[5]);
                    wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);

                }
            }

            function goToNext() {

                if (wheelActiveIndex < wheelInnerItemsLength) {

                    removePrevClass();
                    wheelActiveIndex++;
                    var nextItem = wheel.querySelector('.circle-segment-' + wheelActiveIndex);
                    clickThis(document.querySelector("#info-wheel__svg").querySelectorAll(".segment")[wheelActiveIndex - 1]);
                    wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);

                }
                else if (wheelActiveIndex == wheelInnerItemsLength) {

                    removePrevClass();
                    wheelActiveIndex = 1;
                    var firstItem = wheel.querySelector('.circle-segment-1');
                    clickThis(document.querySelector("#info-wheel__svg").querySelectorAll(".segment")[0]);
                    wheel.classList.add('info-wheel--choice-' + wheelActiveIndex);

                }
            }

            //Prev Btn
            wheelPrevBtn.addEventListener('click', function (el) {
                goToPrev();
            });

            //Next Btn
            wheelNextBtn.addEventListener('click', function (el) {
                goToNext();
            });

            //Add touch gestures (Requires Hammer JS)
            var mcWheel = new Hammer(wheel);
            if (mcWheel) {

                mcWheel.on("swipeleft", function (ev) {
                    //console.log(ev.type);
                    goToNext();
                });

                mcWheel.on("swiperight", function (ev) {
                    //console.log(ev.type);
                    goToPrev();
                });

            }

        }
    }

    this.init = function () {

        be.infoWheel.actions();

    }

}

