var be = be || {};

be.gridSection = new function () {

    function toArray(nodeList) {
        return Array.prototype.slice.call(nodeList);
    }

    this.vCenter = function () {

        const vAlign = toArray(document.querySelectorAll('.module-text.vertical-align'));

        vAlign.forEach(function (el) {

            const parRow = el.closest('.row');

            if (parRow !== null) {
                parRow.classList.add('grid-section__flex-row--vertical');
            }

        });


    }

    this.init = function () {

        be.gridSection.vCenter();
        
    }


};

